import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import getLifecyclesAsOptions from 'shared/api/getLifecyclesAsOptions'
import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import paginatedResponseParser from 'shared/utils/paginatedResponseParser'

import getContextOptionsForPaymentPlans from 'apps/Financial/api/getContextOptionsForPaymentPlans'
import {
  useJourneysSchema,
  useProfileSchema
} from 'apps/Financial/components/SchemasProvider'
import SchemaHelper from 'apps/Financial/services/SchemaHelper'
import usePaymentPlanColumns from './usePaymentPlanColumns'
import useStoredHiddenColumns from './useStoredHiddenColumns'

const STORAGE_FILTER_KEY = 'payment_plans_table:filter:'

export default function PaymentPlansTable({ fetchUrl, setSearch, setFilters }) {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const profileSchema = useProfileSchema()
  const journeysSchema = useJourneysSchema()
  const columns = usePaymentPlanColumns()

  const { onHiddenColumnsChanged, hiddenColumns } = useStoredHiddenColumns([
    ...SchemaHelper.getFieldNames(profileSchema, 'profile'),
    ...SchemaHelper.getFieldNames(journeysSchema, 'journey')
  ])

  return (
    <AutoloadDataTable
      fixedLayout
      fetchURL={fetchUrl}
      loadMoreProps={{ sizeKey: 'limit', offsetKey: 'offset' }}
      fetchResponseParser={paginatedResponseParser}
      emptyMessage={t('No payment plans found.')}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{ 'sort[field]': 'profile.full_name', 'sort[direction]': 1 }}
      searchable
      searchParamKey='search'
      selectableColumns
      columns={columns}
      dataSchemas={[
        {
          schema: profileSchema,
          name: t('Profile fields'),
          accessorPrefix: 'profile'
        },
        {
          schema: journeysSchema,
          name: t('Journey fields'),
          accessorPrefix: 'journey'
        }
      ]}
      filterable
      filters={[
        {
          id: 'context_ids',
          name: t('Product'),
          type: 'multi',
          searchField: 'name',
          valueField: 'id',
          labelField: 'name',
          defaultValue: null,
          defaultLabel: t('All products'),
          loadOptions: getContextOptionsForPaymentPlans
        },
        {
          id: 'publish_status',
          name: t('Publish status'),
          type: 'single',
          options: [
            { value: 'published', label: t('Published') },
            { value: 'not_published', label: t('Not published') }
          ]
        },
        {
          id: 'status',
          name: t('Fee status'),
          type: 'multi',
          options: [
            { value: 'not_due', label: t('No fees due') },
            { value: 'overdue', label: t('Fee due or overdue') },
            { value: 'paid', label: t('All fees paid') }
          ]
        },
        {
          id: 'lifecycle',
          name: t('Lifecycle'),
          type: 'multi',
          loadOptions: getLifecyclesAsOptions
        }
      ]}
      filterStorageKey={STORAGE_FILTER_KEY + user.id}
      onHiddenColumnsChanged={onHiddenColumnsChanged}
      initialHiddenColumns={hiddenColumns}
      onFilterChanged={(filters) => setFilters(filters)}
      onSearchChanged={(search) => setSearch(search)}
    />
  )
}
