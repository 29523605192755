import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

import { useTenantColors } from 'apps/EmailTemplates/hooks/useTenantColors'
import EditorCard from '../EditorCard'

import styles from './styles.module.scss'

export default function BodySettings({ editor }) {
  const { t } = useTranslation()
  const [backgroundColor, setBackgroundColor] = React.useState('')
  const [width, setWidth] = React.useState('')
  const colors = useTenantColors()

  const getBodyElement = useCallback(
    () =>
      editor?.DomComponents.getWrapper().find('[data-gjs-type="mj-body"]')?.[0],
    [editor]
  )

  useEffect(() => {
    const fn = () => {
      const bodyElement = getBodyElement()
      if (!bodyElement) return

      const attributes = bodyElement.getAttributes()
      setWidth(attributes?.width || '600px')
      setBackgroundColor(attributes?.['background-color'] || '')
    }
    editor?.onReady(fn)
  }, [editor, getBodyElement])

  const updateBodyElement = (key, value) => {
    const bodyElement = getBodyElement()
    bodyElement.setAttributes({
      ...bodyElement.getAttributes(),
      [key]: value
    })
  }

  return (
    <EditorCard>
      <Field
        id={'background-color'}
        type='colorPicker'
        className={styles.colorPicker}
        value={backgroundColor || ''}
        label={t('Background color')}
        inputRef={React.useRef()}
        onChange={(value) => {
          setBackgroundColor(value)
          updateBodyElement('background-color', value)
        }}
        inputOptions={{ small: true, defaultColors: colors }}
      />
      <Field
        id={'width'}
        type='prefixedText'
        value={width || '600px'}
        label={t('Width')}
        onChange={(_, value) => {
          setWidth(value)
          updateBodyElement('width', value)
        }}
        inputOptions={{ small: true }}
      />
    </EditorCard>
  )
}
