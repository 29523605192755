export default async function sendVerificationEmail() {
  const result = await fetch('/api/accounts/verification', {
    credentials: 'same-origin',
    method: 'POST'
  })

  if (result.ok) {
    return await result.json()
  }

  throw new Error(result.statusText)
}
