import React from 'react'

import { ColorPicker } from '@fullfabric/alma-mater'

import { useTenantColors } from 'apps/EmailTemplates/hooks/useTenantColors'

import styles from './styles.module.scss'

export const FontColor = ({ editor }) => {
  const value = editor.getAttributes('textStyle').color || 'black'
  const colors = useTenantColors()

  return (
    <ColorPicker
      id='font-color'
      className={styles.container}
      onChange={(value) => editor.chain().focus().setColor(value).run()}
      value={value || ''}
      disableClear
      small
      defaultColors={colors}
    />
  )
}
