import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import paginatedResponseParser from 'shared/utils/paginatedResponseParser'

import { useProfileSchema } from 'apps/Financial/components/SchemasProvider'
import SchemaHelper from 'apps/Financial/services/SchemaHelper'
import { STATES_MAP } from './constants'
import useStoredHiddenColumns from './useStoredHiddenColumns'
import useTransactionColumns from './useTransactionColumns'

const STORAGE_FILTER_KEY = 'transactions_table:filter:'

const TransactionsTable = ({ setSearch, setFilters }) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const profileSchema = useProfileSchema()
  const columns = useTransactionColumns()

  const { onHiddenColumnsChanged, hiddenColumns } = useStoredHiddenColumns([
    ...SchemaHelper.getFieldNames(profileSchema, 'profile')
  ])

  return (
    <AutoloadDataTable
      fixedLayout
      fetchURL='/book_keeper/api/transactions'
      loadMoreProps={{ sizeKey: 'limit', offsetKey: 'offset' }}
      fetchResponseParser={paginatedResponseParser}
      emptyMessage={t('No transactions found.')}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{ 'sort[field]': 'transaction_date', 'sort[direction]': -1 }}
      searchable
      searchParamKey='search'
      selectableColumns
      columns={columns}
      dataSchemas={[
        {
          schema: profileSchema,
          name: t('Profile fields'),
          accessorPrefix: 'profile'
        }
      ]}
      onHiddenColumnsChanged={onHiddenColumnsChanged}
      initialHiddenColumns={hiddenColumns}
      filterable
      filters={[
        {
          id: 'state',
          name: t('State'),
          type: 'single',
          options: Object.keys(STATES_MAP).map((stateName) => ({
            value: stateName,
            label: STATES_MAP[stateName].label
          }))
        }
      ]}
      filterStorageKey={STORAGE_FILTER_KEY + user.id}
      onFilterChanged={(filters) => setFilters(filters)}
      onSearchChanged={(search) => setSearch(search)}
    />
  )
}

export default TransactionsTable
