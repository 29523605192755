import React, { useEffect, useState } from 'react'

import { Field } from '@fullfabric/alma-mater'

export default function FieldTraitSize({
  editor,
  className,
  label,
  name,
  type = 'prefixedText',
  placeholder,
  trueValue
}) {
  const [initialValue, setInitialValue] = useState('')
  const [property, setProperty] = useState(null)
  const elementRef = React.useRef()
  const cursorRef = React.useRef(0)

  useEffect(() => {
    const updateValues = () => {
      const property = editor.TraitManager.getTraits().find(
        (trait) => trait.id === name
      )

      if (!property) return

      setProperty(property)
      const tmp = property.getValue({ useType: true })
      setInitialValue(type === 'checkbox' ? (tmp ? ['true'] : []) : tmp)
    }

    updateValues() // Set the initial values

    editor.on('trait:custom', updateValues)
    return () => editor.off('trait:custom', updateValues)
  }, [editor, name, type])

  useEffect(() => {
    if (type !== 'checkbox')
      elementRef.current?.setSelectionRange(
        cursorRef.current,
        cursorRef.current
      )
  }, [initialValue, type])

  return (
    <Field
      id={name}
      type={type}
      value={initialValue || ''}
      className={className}
      label={type === 'checkbox' ? undefined : label}
      inputRef={elementRef}
      onChange={(event) => {
        if (type === 'checkbox') {
          property?.setValue(elementRef.current.checked ? trueValue : '')
          return
        }

        cursorRef.current = event.target.selectionStart

        const value = event.target.value
        property?.setValue(value)
      }}
      inputOptions={
        type === 'checkbox'
          ? [{ value: 'true', label }]
          : { placeholder, small: true }
      }
    />
  )
}
